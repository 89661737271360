import {defineStore} from "pinia";
import {ModuleStoreStateProps} from "@/models";


export const useModuleStore = defineStore("module", {
  state: (): ModuleStoreStateProps => ({
    confirmFlag: false,
    confirmText: '',
    confirmNotice: [],
    confirmFunc: () => {
    },

    toastFlag: false,
    toastText: '',
    toastType: '',

    mediaType: null,
    mediaUrl: null,
    mediaFlag: false
  }),
  getters: {},
  actions: {
    openConfirm(text: string, func: () => void, notice?: string[]) {
      this.confirmText = text
      this.confirmNotice = notice ?? []
      this.confirmFunc = func
      this.confirmFlag = true
    },
    saveConfirm() {
      this.confirmFunc();
      this.confirmFlag = false
    },
    openToast(text: string, type?: string) {

      if (!type) {
        this.toastType = 'default'
      } else {
        this.toastType = type
      }
      this.toastText = text
      this.toastFlag = true


      // setTimeout(() => {
      //   this.snackbarFlag = false
      // }, 5000)
    },
    changeMediaFlag() {
      this.mediaFlag = !this.mediaFlag
    }

  }
})
