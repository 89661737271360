import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";
import {MissionSetStoreStateProps} from "@/models";


export const useMissionStore = defineStore("mission", {
  state: (): MissionSetStoreStateProps => ({
    items: [],
    query: {
      quarter: 1
    },
    pagination: {
      count: 0,
      next_flag: false,
      page: 1,
      page_numbers: [1],
      per_page: 1,
      prev_flag: false
    },

    count: 0,
    listLoading: false,

    form: null,
    formOrigin: null,
    formLoading: false,
    formSaving: false,

    selectedPosition: {
      lat: null,
      lng: null,
    },
    missionQuestionItems: [],
    map: null,
    currentMarker: null,

    //  마커에 등록되는 데이터 정보
    markerItems: [],
    onlyCreateGameId: null,
    quarter: 1,

    // 지도에 표현되는 마커 dom 자체의 정보
    markerDomList: []
  }),
  getters: {},
  actions: {
    initForm() {
      this.form = {
        game_mission_id: null,
        game_id: null,
        question_id: null,
        name: '',
        reward_id: null,
        latitude: 0,
        longitude: 0,
      };
      this.formOrigin = cloneDeep(this.form)
    },
    resetForm() {
      this.form = cloneDeep(this.formOrigin);
    },

    async onList(gameId: number): Promise<void> {
      this.listLoading = true
      return apiRequest.get({
        url: `/api/game/${gameId}/mission/quarter/${this.quarter}`,
        onError: false,
      })
        .then((data) => {
          //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
          const {apiData} = data;
          this.markerItems = apiData.game_mission_items

          this.pagination = apiData.pagination;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.listLoading = false;
        })
    },


    async onCreate(gameId: number) {
      this.formSaving = true;
      this.form!.latitude = Number(this.form!.latitude)
      this.form!.longitude = Number(this.form!.longitude)
      return apiRequest.post({
        url: `/api/game/${gameId}/mission/quarter/${this.quarter}`,
        data: this.form
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);
          const {apiData} = data;

          this.form!.game_mission_id = apiData.game_mission_id;

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onGet(gameId: number, gameMissionId: number): Promise<void> {
      this.formLoading = true
      return apiRequest.get({
        url: `/api/game/${gameId}/mission/${gameMissionId}`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;

          this.form = apiData.game_mission;
          this.formOrigin = cloneDeep(apiData.game_mission);

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          this.form = null;
          this.formOrigin = null;

          return Promise.reject(error);
        })
        .finally(() => {
          this.formLoading = false;
        })
    },

    async onUpdate(gameId: number, gameMissionId: number) {
      this.formSaving = true;
      this.form!.latitude = Number(this.form!.latitude)
      this.form!.longitude = Number(this.form!.longitude)
      return apiRequest.put({
        url: `/api/game/${gameId}/mission/${gameMissionId}`,
        data: this.form,
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },


    async onGameMissionQuestion(gameId: number, missionId: number): Promise<void> {
      this.formLoading = true
      return apiRequest.get({
        url: `/api/game/${gameId}/question/options/distinct`,
        onError: false,
        params: {
          missionId: missionId
        }
      })
        .then((data) => {
          const {apiData} = data;
          this.missionQuestionItems = apiData.question_options;
          this.formOrigin = cloneDeep(apiData.game);

          return Promise.resolve(apiData);
        })
        .catch((error) => {

          return Promise.reject(error);
        })
        .finally(() => {
          this.formLoading = false;
        })
    },


    async onUpdateLocation(gameId: number, gameMissionId: number) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/game/${gameId}/mission/${gameMissionId}/location`,
        data: this.form,
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onDelete(gameId: number, gameMissionId: number) {
      this.formSaving = true;
      return apiRequest.delete({
        url: `/api/game/${gameId}/mission/${gameMissionId}`,
      })
        .then((data) => {
          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })

    },

    async onDeleteAll(gameId: number) {
      this.formSaving = true;
      return apiRequest.delete({
        url: `/api/game/${gameId}/mission/quarter/${this.quarter}`,
      })
          .then((data) => {
            return Promise.resolve(data.apiData);
          })
          .catch((error) => {
            return Promise.reject(error.apiData);
          })
          .finally(() => {
            this.formSaving = false;
          })

    }

  }

})
