// Composable
import {defineStore} from "pinia";

import {MAxiosAuthorizationStorageType} from "ig_module_ts";
import {setCookie, removeCookie} from "typescript-cookie";

import {AdminAuthStoreStateProps, AdminAuthStoreUserData} from "@/models";
import {apiRequest} from "@/repositories";

export const useAdminAuthStore = defineStore("adminAuth", {
    state: (): AdminAuthStoreStateProps => ({
        user: null,

        // sign in & up
        signInForm: {
            id: "",
            password: "",
        },
        signUpForm: {
            id: "",
            password: "",
            name: "",
            password_verified: "",
        },
        adminId: 0
    }),
    getters: {},
    actions: {
        // private
        _bindingUserData(src?: AdminAuthStoreUserData | null): void {
            this.user = (src === undefined || src === null) ? null : src;
        },

        _bindingToken(src?: any): void {
            const token = src?.token ?? null;
            if (token === null) {
                return;
            }

            switch (apiRequest.authorizationStorageType) {
                case MAxiosAuthorizationStorageType.LocalStorage:
                    localStorage.setItem(apiRequest.authorizationStorageKey, `Bearer ${token}`);
                    break;
                case MAxiosAuthorizationStorageType.SessionStorage:
                    sessionStorage.setItem(apiRequest.authorizationStorageKey, `Bearer ${token}`);
                    break;
                case MAxiosAuthorizationStorageType.Cookies:
                    setCookie(apiRequest.authorizationStorageKey, `Bearer ${token}`);
                    break;
            }
        },

        _removeToken(): void {
            switch (apiRequest.authorizationStorageType) {
                case MAxiosAuthorizationStorageType.LocalStorage:
                    localStorage.removeItem(apiRequest.authorizationStorageKey);
                    break;
                case MAxiosAuthorizationStorageType.SessionStorage:
                    sessionStorage.removeItem(apiRequest.authorizationStorageKey);
                    break;
                case MAxiosAuthorizationStorageType.Cookies:
                    removeCookie(apiRequest.authorizationStorageKey);
                    break;
            }
        },

        // api
        async onCheck(): Promise<void> {
            return apiRequest.get({
                url: "/api/admin/check",
                onError: false,
                authorization: true,
            }).then((data) => {
                this._bindingUserData(data.apiData);
                this.adminId = data.apiData.admin_id;
                return Promise.resolve();
            }).catch(() => {
                this._bindingUserData(null);
                return Promise.reject();
            });
        },

        async onSignIn(): Promise<any> {
            return apiRequest.post({
                url: "/api/admin/sign-in",
                data: {
                    id: this.signInForm.id,
                    password: this.signInForm.password
                },
            })
                .then(({apiData}) => {
                    this._bindingToken(apiData);
                })
                .catch((err) => {

                    this._removeToken();
                    return Promise.reject(err);

                })
        },

        async onSignUp(): Promise<any> {
            return apiRequest.post({
                url: `/api/admin/sign-up`,
                data: this.signUpForm
            })
                .then((data) => {

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                })
        },

        async logout(): Promise<void> {
            this._removeToken();
            return Promise.resolve();
        },

        initForm() {
            this.signInForm = {
                id: "",
                password: "",
            }

            this.signUpForm = {
                id: "",
                password: "",
                name: "",
                password_verified: "",
            }
        },

        // functions
        getUserData(keyName: string): string | number | boolean | null {
            return (this.user === null) ? null : (this.user[keyName] ?? null)
        }
    }
});
