import {MAxios, MAxiosAuthorizationStorageType} from "ig_module_ts";
import {useModuleStore} from "@/stores";

export const apiRequest = new MAxios({
  baseUrl: import.meta.env.VITE_API_URL,
  onError(message, error) {
    useModuleStore().openToast(message || '에러가 발생하였습니다. 새로고침후 진행해주세요', 'error')
  },
  authorizationStorageType: MAxiosAuthorizationStorageType.LocalStorage,
});
