import {GameRoundStoreStateProps} from "@/models";
import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";


export const useGameRoundStore = defineStore("gameRound", {
    state: (): GameRoundStoreStateProps => ({
        items: [],
        roundItems: null,

        query: {
            page: 1,
            perPage: 50,
            name: ''
        },
        pagination: {
            count: 0,
            next_flag: false,
            page: 1,
            page_numbers: [1],
            per_page: 1,
            prev_flag: false
        },

        count: 0,
        listLoading: false,

        form: null,
        formOrigin: null,
        formLoading: false,
        formSaving: false,

        options: [],
        messageItems: [],
        gameResultData: {}
    }),
    getters: {},
    actions: {
        initForm() {
            this.form = {
                currentRound: null,
            };
            this.formOrigin = cloneDeep(this.form)
        },
        resetForm() {
            this.form = cloneDeep(this.formOrigin);
        },

        async onList(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/dashboard/info`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.roundItems = apiData.game_info;
                    this.pagination = apiData.pagination;

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.listLoading = false;
                })
        },

        async onGetMissionResult(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/dashboard/result`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.gameResultData = apiData.game_result;

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.listLoading = false;



                })
        },

        async onGameStatusUpdate(gameId: number, status: string) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/game/${gameId}/status/${status}`,
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onGetMessageList(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/message`,
                onError: false,
            })
                .then((data) => {
                    //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
                    const {apiData} = data;
                    this.messageItems = apiData.game_message_items

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {

                    this.listLoading = false;
                })
        },

        async onCreateMessage(gameId: number, gameTeamId: number, message: string) {
            this.formSaving = true;
            return apiRequest.post({
                url: `/api/game/${gameId}/message`,
                data: {
                    game_team_id: gameTeamId,
                    message: message
                }
            })
                .then((data) => {

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onGetPdf(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/dashboard/pdf`,
                onError: false,
            })
                .then((data: any) => {
                    const apiData = data.apiData;

                    const byteCharacters = atob(apiData.data);
                    const byteNumbers = new Uint8Array(byteCharacters.length);

                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }

                    const blob = new Blob([byteNumbers], { type: 'application/pdf' });

                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download =  apiData.pdf_name;
                    link.click();

                    // 메모리 정리
                    URL.revokeObjectURL(link.href);

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {

                    this.listLoading = false;
                })
        },


    }

})
