import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";
import {QuestionSetListItem, QuestionSetStoreStateProps} from "@/models";


export const useQuestionSetStore = defineStore("questionSet", {
  state: (): QuestionSetStoreStateProps => ({
    items: [],
    query: {
      page: 1,
      perPage: 50,
      name: '',
      questionId: 0
    },
    pagination: {
      count: 0,
      next_flag: false,
      page: 1,
      page_numbers: [1],
      per_page: 1,
      prev_flag: false
    },

    count: 0,
    listLoading: false,

    form: null,
    formOrigin: null,
    formLoading: false,
    formSaving: false,

    options: [],
    endingOptions: [],
    introOptions: [],
    finalOptions: [],
    optionClassType: [],

    connectedList: []

  }),
  getters: {},
  actions: {
    initForm() {
      this.form = {
        question_set_id: 0,
        name: ""
      };
      this.formOrigin = cloneDeep(this.form)
    },
    resetForm() {
      this.form = cloneDeep(this.formOrigin);
    },
    async onList(): Promise<void> {
      this.listLoading = true
      return apiRequest.get({
        url: `/api/question-set`,
        onError: false,
        params: this.query,
      })
        .then((data) => {
          //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
          const {apiData} = data;
          this.items = apiData.question_set_items.map((item :QuestionSetListItem) => ({
            question_set_id: item.question_set_id,
            name: item.name,
            num_questions: item.num_questions,
            created_at: item.created_at,
            num_connected_games: item.num_connected_games,
          }))

          this.pagination = apiData.pagination;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.listLoading = false;
        })
    },

    async getOptions(): Promise<void> {
      return apiRequest.get({
        url: `/api/question-set/options`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.options = apiData.question_set_options;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
        })
    },

    async getConnectedList(questionSetId: number): Promise<void> {
      return apiRequest.get({
        url: `/api/question-set/${questionSetId}/game-connected`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.connectedList = apiData.game_briefs;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
        })
    },

    async getOptionClassType(questionSetId: number, typeName: string): Promise<void> {
      return apiRequest.get({
        url: `/api/question-set/${questionSetId}/question/options/classType/${typeName}`,
        onError: false,
      })
        .then((data) => {
          //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
          const {apiData} = data;

          if (typeName === 'intro') {
            this.introOptions = apiData.question_options;
          } else if (typeName === 'final') {
            this.finalOptions = apiData.question_options;
          } else if (typeName === 'ending') {
            this.endingOptions = apiData.question_options;
          }

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
        })
    },


    async onCreate() {
      this.formSaving = true;
      return apiRequest.post({
        url: `/api/question-set`,
        data: this.form
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onGet(questionSetId: number): Promise<void> {
      this.formLoading = true
      return apiRequest.get({
        url: `/api/question-set/${questionSetId}`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.form = apiData.question_set;
          this.formOrigin = cloneDeep(apiData.question_set);

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          this.form = null;
          this.formOrigin = null;

          return Promise.reject(error);
        })
        .finally(() => {
          this.formLoading = false;
        })
    },

    async onUpdate(questionSetId: number) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/question-set/${questionSetId}`,
        data: this.form,
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onDelete(questionSetId: number) {
      this.formSaving = true;
      return apiRequest.delete({
        url: `/api/question-set/${questionSetId}`,
      })
        .then((data) => {
          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })

    }

  }

})
