// Plugins
import {pinia, vuetify, vueMeta, vueMetaPlugin, vueRouter} from '@/plugins'
import '@vuepic/vue-datepicker/dist/main.css'

// Components
import '@/styles/main.scss'
import App from '@/App.vue'

// Composable
import { createApp } from 'vue'

const app = createApp(App)
app.use(vuetify)
app.use(pinia)
app.use(vueRouter)
app.use(vueMeta)
app.use(vueMetaPlugin)
app.mount('#app')
