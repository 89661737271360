import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";
import {TenantStoreStateProps} from "@/models";


export const useTenantStore = defineStore("tenant", {
    state: (): TenantStoreStateProps => ({
        items: [],
        query: {
            page: 1,
            perPage: 50,
            name: ''
        },
        pagination: {
            count: 0,
            next_flag: false,
            page: 1,
            page_numbers: [1],
            per_page: 10,
            prev_flag: false
        },

        count: 0,
        listLoading: false,

        form: null,
        formOrigin: null,
        formLoading: false,
        formSaving: false,

        userMissionStatusForm: [],
        gameId: 0,
        round: 0,

    }),
    getters: {},
    actions: {
        initForm() {
            this.form = {
                admin_email: "",
                admin_id: "",
                admin_name: "",
                admin_password: "",
                admin_password_verified: "",
                name: "",
                tenant_id: 0
            };

            this.formOrigin = cloneDeep(this.form)
        },
        resetForm() {
            this.form = cloneDeep(this.formOrigin);
        },
        async onList(): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/tenant`,
                onError: false,
                params: this.query,
            })
                .then((data) => {
                    //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
                    const {apiData} = data;
                    this.items = apiData.tenant_items;
                    this.pagination = apiData.pagination;

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.listLoading = false;


                })
        },

        async onCreate() {
            this.formSaving = true;
            return apiRequest.post({
                url: `/api/tenant`,
                data: this.form
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onGet(tenantId: number): Promise<void> {
            this.formLoading = true
            return apiRequest.get({
                url: `/api/tenant/${tenantId}`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.form = apiData.tenant

                    this.formOrigin = cloneDeep(apiData.tenant);

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    this.form = null;
                    this.formOrigin = null;

                    return Promise.reject(error);
                })
                .finally(() => {
                    this.formLoading = false;

                })
        },

        async onUpdate(tenantId: number) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/tenant/${tenantId}`,
                data: this.form,
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onDelete(tenantId: number) {
            this.formSaving = true;
            return apiRequest.delete({
                url: `/api/tenant/${tenantId}`,
            })
                .then((data) => {
                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onGetTenantMissionDetail(tenantId: number, gameId: number, round: number): Promise<void> {
            this.formLoading = true
            return apiRequest.get({
                url: `/api/tenant/${tenantId}/game/${gameId}/round/${round}/mission`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;

                    this.userMissionStatusForm = apiData.game_mission_items
                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    // this.form = null;
                    // this.formOrigin = null;

                    return Promise.reject(error);
                })
                .finally(() => {
                    this.formLoading = false;

                })
        },

    }

})
