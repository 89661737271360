import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";
import {UserStoreStateProps} from "@/models";


export const useUserStore = defineStore("user", {
  state: (): UserStoreStateProps => ({
    items: [],
    query: {
      page: 1,
      perPage: 50,
      name: ''
    },
    pagination: {
      count: 0,
      next_flag: false,
      page: 1,
      page_numbers: [1],
      per_page: 1,
      prev_flag: false
    },

    count: 0,
    listLoading: false,

    form: null,
    formOrigin: null,
    formLoading: false,
    formSaving: false,

    userMissionStatusForm: [],
    gameId: 0,
    round: 0,

  }),
  getters: {},
  actions: {
    initForm() {
      this.form = {
        ages: 0,
        email: "",
        game_brief_items: [
          {
            created_at: "",
            game_id: 0,
            game_name: "",
            mission_percentage: 0,
            round: 0,
            user_id: 0
          }
        ],
        gender_type: 0,
        name: "",
        user_id: 0
      };

      this.formOrigin = cloneDeep(this.form)
    },
    resetForm() {
      this.form = cloneDeep(this.formOrigin);
    },
    async onList(): Promise<void> {
      this.listLoading = true
      return apiRequest.get({
        url: `/api/user`,
        onError: false,
        params: this.query,
      })
        .then((data) => {
          //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
          const {apiData} = data;
          this.items = apiData.user_items;
          this.pagination = apiData.pagination;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.listLoading = false;

        })
    },

    async onCreate() {
      this.formSaving = true;
      return apiRequest.post({
        url: `/api/user`,
        data: this.form
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onGet(userId: number): Promise<void> {
      this.formLoading = true
      return apiRequest.get({
        url: `/api/user/${userId}`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.form = apiData.user

          this.formOrigin = cloneDeep(apiData.user);

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          this.form = null;
          this.formOrigin = null;

          return Promise.reject(error);
        })
        .finally(() => {
          this.formLoading = false;

        })
    },

    async onUpdate(userId: number) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/user/${userId}`,
        data: this.form,
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onDelete(userId: number) {
      this.formSaving = true;
      return apiRequest.delete({
        url: `/api/user/${userId}`,
      })
        .then((data) => {
          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onGetUserMissionDetail(userId: number, gameId: number, round: number): Promise<void> {
      this.formLoading = true
      return apiRequest.get({
        url: `/api/user/${userId}/game/${gameId}/round/${round}/mission`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;

          this.userMissionStatusForm = apiData.game_mission_items
          return Promise.resolve(apiData);
        })
        .catch((error) => {
          // this.form = null;
          // this.formOrigin = null;

          return Promise.reject(error);
        })
        .finally(() => {
          this.formLoading = false;

        })
    },

  }

})
