import { GameTeamStoreStateProps} from "@/models";
import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";


export const useGameTeamStore = defineStore("gameTeam", {
    state: (): GameTeamStoreStateProps => ({
        items: [],

        messageTeamItems: [
            {game_team_id: 0, name: '전체'}
        ],

        query: {
            page: 1,
            perPage: 50,
            name: ''
        },
        pagination: {
            count: 0,
            next_flag: false,
            page: 1,
            page_numbers: [1],
            per_page: 1,
            prev_flag: false
        },

        count: 0,
        listLoading: false,

        form: null,
        formOrigin: null,
        formLoading: false,
        formSaving: false,

        options: [],
    }),
    getters: {},
    actions: {
        initForm() {
            this.form = {
                name: ''
            };
            this.formOrigin = cloneDeep(this.form)
        },
        resetForm() {
            this.form = cloneDeep(this.formOrigin);
        },

        async onList(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/team`,
                onError: false,
                params: this.query,

            })
                .then((data) => {
                    const {apiData} = data;
                    this.items = apiData.game_team_items;
                    this.messageTeamItems = [
                        {game_team_id: 0, name: '전체'},
                        ...apiData.game_team_items
                    ];

                    // this.items.push({
                    //     final_mission_solved_type: 1,
                    //     game_id: 59093138309245,
                    //     game_team_id: 59093460320414,
                    //     mission_percentage: 0,
                    //     name: "ㅅ211",
                    //     num_mission_processed: 0,
                    //     num_mission_success: 0,
                    //     num_missions: 2,
                    //     num_users: 0,
                    //     point: 5
                    // })
                    this.pagination = apiData.pagination;

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.listLoading = false;
                })
        },

        async onCreate(gameId: number, teamNumber: number | null) {
            this.formSaving = true;
            return apiRequest.post({
                url: `/api/game/${gameId}/team`,
                data: {num_teams: teamNumber }
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onDelete(gameId: number, gameTeamId: number) {
            this.formSaving = true;
            return apiRequest.delete({
                url: `/api/game/${gameId}/team/${gameTeamId}`,
            })
                .then((data) => {
                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })

        },

        async onDeleteAll(gameId: number) {
            this.formSaving = true;
            return apiRequest.delete({
                url: `/api/game/${gameId}/team/all`,
            })
                .then((data) => {
                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })

        },


        async onUpdateTeamInfo(gameId: number) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/game/${gameId}/team/all`,
                data: {forms: this.items.map((ele)=> {
                    return {
                        game_team_id: ele.game_team_id,
                        name: ele.name
                    }
                    })},
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

    }

})
