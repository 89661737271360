import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";
import {MasterStoreStateProps } from "@/models";


export const useMasterStore = defineStore("master", {
    state: (): MasterStoreStateProps => ({
        items: [],
        query: {
            page: 1,
            perPage: 50,
            name: ''
        },
        pagination: {
            count: 0,
            next_flag: false,
            page: 1,
            page_numbers: [1],
            per_page: 10,
            prev_flag: false
        },

        count: 0,
        listLoading: false,

        form: null,
        formOrigin: null,
        formLoading: false,
        formSaving: false,


    }),
    getters: {},
    actions: {
        initForm() {
            this.form = {
                admin_master_id: 0,
                id: '',
                name: '',
                password: '',
                password_verified: '',
                access_flag: false,
            };

            this.formOrigin = cloneDeep(this.form)
        },
        resetForm() {
            this.form = cloneDeep(this.formOrigin);
        },
        async onList(): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/admin-master`,
                onError: false,
                params: this.query,
            })
                .then((data) => {
                    //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
                    const {apiData} = data;
                    this.items = apiData.admin_master_items;
                    this.pagination = apiData.pagination;

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.listLoading = false;


                })
        },

        async onCreate() {
            this.formSaving = true;
            return apiRequest.post({
                url: `/api/admin-master`,
                data: this.form
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onGet(adminMasterId: number): Promise<void> {
            this.formLoading = true
            return apiRequest.get({
                url: `/api/admin-master/${adminMasterId}`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.form = apiData.admin_master

                    this.formOrigin = cloneDeep(apiData.admin_master);

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    this.form = null;
                    this.formOrigin = null;

                    return Promise.reject(error);
                })
                .finally(() => {
                    this.formLoading = false;

                })
        },

        async onUpdate(adminMasterId: number) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/admin-master/${adminMasterId}`,
                data: this.form,
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onUpdateAccess(adminMasterId: number) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/admin-master/${adminMasterId}/access`,
                data: this.form,
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);
                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onDelete(adminMasterId: number) {
            this.formSaving = true;
            return apiRequest.delete({
                url: `/api/admin-master/${adminMasterId}`,
            })
                .then((data) => {
                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },


    }

})
