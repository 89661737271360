import {defineStore} from "pinia";
import {FileStoreStateProps} from "@/models";
import {apiRequest} from "@/repositories";


export const useFileStore = defineStore("file", {
  state: (): FileStoreStateProps => ({
    postFileId: 0,
    serviceName: 'reward',
    mediaId: 0,
    mediaUrl: '',
    mediaType: '',
    youtubeUrl: '',

    logo: [],
    background: []
  }),
  getters: {},
  actions: {
    async postFile(serviceName: string, files: FormData): Promise<void> {
      return apiRequest.post({
        url: `/api/file/${serviceName}/upload`,
        data: files,
        onError: false,
        headers: {
          "Content-Type": 'multipart/form-data'
        }
      })
        .then((data) => {
          const {apiData} = data;

          this.mediaId = apiData.file_id
          this.mediaUrl = apiData.file_url
          this.mediaType = apiData.file_type


          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })

    },

    async getFile(fileId: string): Promise<void> {
      return apiRequest.get({
        url: `/api/file/${fileId}`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;

          this.mediaId = apiData.file_id
          this.mediaUrl = apiData.file_url
          this.mediaType = apiData.file_type


          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })

    },

    async getGameListFile(): Promise<void> {
      return apiRequest.get({
        url: `/api/file/list/game`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;

          this.logo = apiData.logo
          this.background = apiData.background


          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })

    },

    deleteFile() {
      this.mediaId = 0
      this.mediaUrl = ''
      this.mediaType = ''
    }


  }
})
