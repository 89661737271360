// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composable
import {createVuetify} from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export const vuetify = createVuetify({
  theme: {
    defaultTheme: 'myCustomTheme',
    themes: {
      myCustomTheme: {
        dark: false, // 다크 모드 여부
        colors: {
          primary: '#9F67F0',
          primaryLight: '#FFEDE0',
          secondary: '#7367f0',
          gray: '#F5F5F5',
          dark_gray: '#363740',
          deep_gray: '#f5f7f9',
          accent: '#8c9eff',
          error: '#b71c1c',
          my_color: '#ffffff',
          font_gray: '#5E5873',
        },
        variables: {
        },

      },
    },
  },
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1024,
    },
  }
})
