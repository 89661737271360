import {GameRoundUserMissionStoreStateProps} from "@/models";
import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";


export const useGameRoundUserMissionStore = defineStore("gameRoundUserMission", {
  state: (): GameRoundUserMissionStoreStateProps => ({
    items: [],
    query: {
      page: 1,
      perPage: 50,
      name: ''
    },
    pagination: {
      count: 0,
      next_flag: false,
      page: 1,
      page_numbers: [1],
      per_page: 1,
      prev_flag: false
    },

    count: 0,
    listLoading: false,

    form: null,
    formOrigin: null,
    formLoading: false,
    formSaving: false,


    options: [],
    round: 0,
    game_team_id: 0,
    solved_type: 0,
  }),
  getters: {},
  actions: {
    initForm() {
      this.form = {
        currentRound: null,
      };
      this.formOrigin = cloneDeep(this.form)
    },
    resetForm() {
      this.form = cloneDeep(this.formOrigin);
    },

    async onList(gameId: number, gameTeamId: number): Promise<void> {
      this.listLoading = true
      return apiRequest.get({
        url: `/api/game/${gameId}/team/${gameTeamId}/mission`,
        onError: false,
      })
        .then((data) => {
          //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
          const {apiData} = data;
          this.items = apiData.game_mission_items;
          this.pagination = apiData.pagination;


          // 셀렉트 박스 테스트
          // this.items.push({
          //   arrived_point: 0,
          //   corrected_point: 0,
          //   game_answer: {answer: "", answer_media: {file_id: 0, url: "", type: "", width: 0, height: 0}},
          //   game_id: 59093138309269,
          //   game_mission_id: 59093153054871,
          //   latitude: 37.513332978562715,
          //   longitude: 127.1018251465266,
          //   name: "ㅁㄴㅇㄹ",
          //   order: 1,
          //   question: "미션 텍스트 사진",
          //   question_id: 58823857668129,
          //   solved_type: 2
          // })

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {


          this.listLoading = false;
        })
    },

    async onAddUserMissionPoint(gameId: number, gameTeamId: number, gameMissionId:number, point: number) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/game/${gameId}/team/${gameTeamId}/mission/${gameMissionId}/point`,
        data: {
          acquire_point: point
        }
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onChangeUserMissionStatus(gameId: number, gameTeamId: number, gameMissionId:number, type: number) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/game/${gameId}/team/${gameTeamId}/mission/${gameMissionId}/solved`,
        data: {
          solved_type: type
        }
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },


  }

})
